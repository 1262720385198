import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import './CompleteProjectDrawer.scss';

function CompleteProjectDrawer({user, changeUser, givePoints}) {

    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    function handleCloseClick() {
        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.completeProjectDrawer = null;
            changeUser({...user});
            setLockStyle(false);
        }, 1000);

        console.log('trigger parentStyles trigger')
    };

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              handleCloseClick();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, user, changeUser]);

    let className = 'CompleteProjectDrawer drawer';

    if (!user) {
        return (<div></div>);
    }
    if (!user.completeProjectDrawer) {
        return (<div></div>);
    }

    const project = user.completeProjectDrawer;
    var leftOrRight = UI.isProjectOnLeftOrRight(user, project);

    if (!lockStyle && project && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const parentStyles = {};
    if (leftOrRight === 'left') {
        parentStyles.right = '0px';
    } else {
        //parentStyles.left = '0px';
    }
    parentStyles.opacity = opacity;
    parentStyles.right = opacity === "0.5" ? "-480px" : "0px" 

    console.log('parentStyles', parentStyles, user.completeProjectDrawer);

    const handleCompleteProjectClick = async () => {
        const newUser = await Back.completeProject(project.id);

        if (!newUser) {
            return handleCloseClick();
        }

        user.projects = [...newUser.projects];
        user.has_pending_project_slot = newUser.has_pending_project_slot;
        user.ranks_needed_for_project_slot = newUser.ranks_needed_for_project_slot;
        user.completeProjectDrawer = null;

        givePoints({
            'Impact': decideImpactReward(project),
            'Growth': decideGrowthReward(project)
        });

        changeUser({...user});

        UI.toastMessageWithEarnings("You completed your " + project.title + " project!", null, null, decideImpactReward(project), decideGrowthReward(project));
    };

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>
            <div className='big-subheading'>Complete Project</div>
            <div className='big-heading'>
                {project.title}
            </div>

            <img src="/project-complete.png" className='peak-img' />

            <div className='rewards-subheading'>
                You'll Earn
            </div>

            <div className='rewards'>
                <div className="reward-content">
                    <div className='reward reward-impact'>
                        <div className='label'>{decideImpactReward(project)}</div>
                    </div>
                    <div className="rank-label rank-label-impact">Impact</div>
                </div>
                <div className="reward-content">
                    <div className='reward reward-growth'>
                        <div className='label'>{decideGrowthReward(project)}</div>
                    </div>
                    <div className="rank-label rank-label-growth">Growth</div>
                </div>
            </div>

            <div className='complete-button' onClick={handleCompleteProjectClick}>Complete Project</div>

        </div>
    )
};

function decideImpactReward(project) {
    var minutes = 0;

    for (var s in project.sessions_history) {
        const session = project.sessions_history[s];

        if (session.status === "Completed") {
            minutes += session.completed_par;
        }
    }

    console.log('minutes', project.sessions_history, minutes);

    return roundNearest5(minutes / 2);
}

function decideGrowthReward(project) {
    var sessionCount = 0;

    for (var s in project.sessions_history) {
        const session = project.sessions_history[s];
        console.log('session', session);

        if (session.status === "Completed") {
            sessionCount += 1;
        }
    }

    console.log('sessionCount', sessionCount);

    return roundNearest5(sessionCount * 3);
}

function roundNearest5(num) {
    let value = Math.round(num / 5) * 5;

    if (value < 5) {
        value = 5;
    }

    return value;
  }

export default CompleteProjectDrawer;