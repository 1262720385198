import React, { useEffect, useState } from 'react';
import Back from '../../helpers/Back';
import './ChooseThought.scss';
import ThoughtOption from './ThoughtOption';

function ChooseThought({session, project, onThoughtSelected, onThoughtFilling}) {

    const initialThoughts = [
        {
            id: 1,
            label: "Things are moving along well",
            type: "Impact"
        },
        {
            id: 2,
            label: "I can feel myself improving",
            type: "Growth"
        },
        {
            id: 3,
            label: "I can tell I'm doing well at this",
            type: "Joy"
        },
        {
            id: 4,
            label: "Progress is happening",
            type: "Impact"
        },
        {
            id: 5,
            label: "I feel good working on this",
            type: "Growth"
        }
    ];

    const [thoughts, setThoughts] = useState(initialThoughts);
    const [headingOpacity, setHeadingOpacity] = useState('1');
    const [headingHeight, setHeadingHeight] = useState('1');

    useEffect(
        () => {
            loadSuggestions();
        }, []);

    const loadSuggestions = async () => {
        const suggestions = await Back.suggestThoughts(session.project_id, session.rank);
        console.log('sugg2', suggestions);
        setThoughts(suggestions);
    };

    const onThoughtFillingHook = (thought) => {
        const newThoughts = [];
        const goneThoughts = [];

        for (var i = 0; i < thoughts.length; i++) {
            const newThought = thoughts[i];

            if (newThought.id !== thought.id) {
                newThought.is_fading = true;
            }

            newThoughts.push(newThought);

            const goneThought = {...newThought};
            if (goneThought.id !== thought.id) {
                goneThought.is_gone = true;
            }
            goneThoughts.push(goneThought);
        }

        console.log('newThoughts', newThoughts, thought);

        setThoughts(newThoughts);
        setHeadingOpacity('0');

        setTimeout(() => {
            console.log('goneThoughts', goneThoughts);
            setThoughts(goneThoughts);
            setHeadingHeight('0');
        }, 1400);

        onThoughtFilling(thought);
    };

    const onThoughtSelectedHook = (thought) => {
        console.log('hook', thought);
        onThoughtSelected(thought);
    };

    return (
        <div className="ChooseThought">
            <div className='heading' style={{opacity:headingOpacity, height:headingHeight === '1' ? '19px' : '0px'}}>Choose a thought that feels true.</div>
            <div className='options'>
                {thoughts.map(thought => <ThoughtOption thought={thought} session={session} project={project} key={thought.id} onThoughtFilling={onThoughtFillingHook} onThoughtSelected={onThoughtSelectedHook}></ThoughtOption>)}
            </div>
        </div>
    )
}

export default ChooseThought;