import React, { useEffect, useState } from 'react';
import './RankWidget.scss';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { animated, useSpring, config, useTransition } from '@react-spring/web'

import { useContext } from 'react';
import UserContext from '../../UserContext';

function RankWidget({widget}) {

    const {user, changeUser} = useContext(UserContext);
    const [showJustAdded, setShowJustAdded] = useState(false);
    const [animatingJustAdded, setAnimatingJustAdded] = useState(false);

    const [shownRankChangeId, setShownRankChangeId] = useState(null);

    const userRank = user.ranks.find(r => r.name === widget.name);
    
    // let progressPercentage = 0;
    // if (userRank.points > 0) {
    //     progressPercentage = userRank.points / userRank.goalPoints * 100;
    // }

    // const [currentProgress, setCurrentProgress] = useState(progressPercentage);
    const currentProgress = userRank.points / userRank.goalPoints * 100;

    const [show, set] = useState(false)

    const [transitionItems, setTransitionItems] = useState([]);
    const transitions = useTransition(transitionItems, {
        from: { opacity: 0, y: -20 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 12 },
        reverse: show,
        delay: 250,
        config: config.stiff,
        //onRest: () => setTransitionItems([])
        onRest: () => {
            setTimeout(() => {
                setTransitionItems([])
            }, 650);
        }
    });

    let justAddedContent = null;

    //console.log('change user rank', user);

    if (user && user.showRankChangeId && user.showRankChangeId[widget.name] && user.showRankChangeId[widget.name] !== shownRankChangeId) {
        setShownRankChangeId(user.showRankChangeId[widget.name]);
        setTransitionItems(['+' + user.showRankChangeNumber[widget.name]]);
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowJustAdded(true);
    //         setTimeout(() => {
    //             setShowJustAdded(false);
    //         }, 1000);
    //     }, 1000);
    // }, []);

    return (
        <div className="RankWidget">

            <div className="just-added-container">
                <div className="just-added">
                    {transitions((styles, item) => item && <animated.div style={{color: widget.primaryColor, ...styles}}>
                        {item}
                    </animated.div>)}
                </div>
            </div>
            
            <div className='circle'>
                <CircularProgressbar
                    value={currentProgress}
                    text={userRank.level}
                    styles={buildStyles({
                        pathColor: widget.primaryColor,
                        trailColor: widget.lightColor,
                        textSize: '32px',
                        textColor: widget.primaryColor
                    })}
                    strokeWidth={8}
                />
            </div>
            <div className='content'>
                <div className='label' style={{'color': widget.primaryColor}}>{widget.label}</div>
            </div>
            
        </div>
    )
}

export default RankWidget;