import React, { useState } from 'react';
import HealthCard from '../HealthCard/HealthCard';
import HealthGrid from '../HealthGrid/HealthGrid';
import SessionCard from '../SessionCard/SessionCard';
import './ProjectCard.scss';
import ProjectWin from './ProjectWin';
import UserContext from '../../UserContext';

import { useDrop } from 'react-dnd';
import NewSessionCard from '../SessionCard/NewSessionCard';
import { useContext } from 'react';

import { animated, useSpring, config, useTransition } from '@react-spring/web'
import ProjectMenu from '../ProjectMenu/ProjectMenu';
import BlankHealthCard from '../HealthCard/BlankHealthCard';
import WizardPop from '../Wizard/WizardPop';
import Back from '../../helpers/Back';
import ProjectGem from '../ProjectGem/ProjectGem';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import UI from '../../helpers/UI';

function ProjectCard(props) {

    const {user, changeUser} = useContext(UserContext);

    const [project, setProject] = useState(props.project);

    const [placeholderSessions, setPlaceholderSessions] = useState([
       {
           rank: "Impact",
           status: "Pending",
           project: project,
           project_id: project.id,
           par: 1
       },
       {
        rank: "Growth",
        status: "Pending",
        project: project,
        project_id: project.id,
        par: 1
       },
        {
        rank: "Joy",
        status: "Pending",
        project: project,
        project_id: project.id,
        par: 1
        } 
    ]);

    const isWizard = project.title === "Practice Project";

    const onNewWin = (win, user, session) => {
        
        const newProject = user.projects.find(p => p.id === project.id);

        const sessionIndex = newProject.sessions.findIndex(s => s.id === session.id);
        newProject.sessions[sessionIndex] = session;

        setProject(newProject);

        const newWinTransitionItems = [...winTransitionItems];
        newWinTransitionItems.unshift(win);
        setWinTransitionItems(newWinTransitionItems);
    };

    const [showTransition, setShowTransition] = useState(false)
    const [winTransitionItems, setWinTransitionItems] = useState(project.wins.slice(0, 2));
    const winTransitions = useTransition(winTransitionItems, {
        from: { opacity: 0, y: -20 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 12 },
        reverse: showTransition,
        delay: 250,
        config: config.stiff,
        onRest: () => {}
    });

    const handleCompleteProjectClick = () => {

    };

    const [isWelcomePopOpen, setIsWelcomePopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.welcome !== 1);
    const [isProjectPopOpen, setIsProjectPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "projects");
    const [isSessionsPopOpen, setIsSessionsPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "sessions");
    const [isImpactSessionsPopOpen, setIsImpactSessionsPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "impact-sessions");
    const [isGrowthSessionsPopOpen, setIsGrowthSessionsPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "growth-sessions");
    const [isEaseSessionsPopOpen, setIsEaseSessionsPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "ease-sessions");
    const [isFirstSessionPopOpen, setIsFirstSessionPopOpen] = useState(project.title === "Practice Project" && user.context.Wizard.nextStep === "firstSession");
    const [isThoughtsPopOpen, setIsThoughtsPopOpen] = useState(project.title === "Practice Project");
    const [isCompleteProjectPopOpen, setIsCompleteProjectPopOpen] = useState(project.title === "Practice Project");

    if (!user) {
        return <div></div>;
    }

    const handleCloseWelcomePop = () => {
        setIsWelcomePopOpen(false);

        const response = Back.completeWizardStep("welcome");
        
        setTimeout(() => {
            setIsProjectPopOpen(true);
        }, 100);
    };

    const handleCloseProjectsPop = () => {
        setIsProjectPopOpen(false);

        const response = Back.completeWizardStep("projects");

        setTimeout(() => {
            setIsSessionsPopOpen(true);
        }, 100);
    };

    const handleCloseSessionsPop = () => {
        setIsSessionsPopOpen(false);

        const response = Back.completeWizardStep("sessions");

        setTimeout(() => {
            setIsImpactSessionsPopOpen(true);
        }, 100);
    };

    const handleCloseImpactSessionsPop = () => {
        setIsImpactSessionsPopOpen(false);

        const response = Back.completeWizardStep("impact-sessions");

        setTimeout(() => {
            setIsGrowthSessionsPopOpen(true);
        }, 100);
    };

    const handleCloseGrowthSessionsPop = () => {
        setIsGrowthSessionsPopOpen(false);

        const response = Back.completeWizardStep("growth-sessions");

        setTimeout(() => {
            setIsEaseSessionsPopOpen(true);
        }, 100);
    };

    const handleCloseEaseSessionsPop = () => {
        setIsEaseSessionsPopOpen(false);

        const response = Back.completeWizardStep("ease-sessions");

        setTimeout(() => {
            setIsFirstSessionPopOpen(true);
        }, 100);
    };

    const handleCloseFirstSessionPop = (e) => {
        setIsFirstSessionPopOpen(false);

        const response = Back.completeWizardStep("firstSession");

        user.context.Wizard.nextStep = 'startedSession';
        changeUser({...user});
    };

    const handleCloseThoughtsPop = () => {
        setIsThoughtsPopOpen(false);

        const response = Back.completeWizardStep("thoughts");

        setTimeout(() => {
            setIsCompleteProjectPopOpen(true);
        }, 100);      
    };

    const handleCloseCompleteProjectPop = () => {
        setIsCompleteProjectPopOpen(false);

        const response = Back.completeWizardStep("completeProject");
    };

    let className = 'ProjectCard';

    var totalMinutes = 0;
    for (var w in project.wins) {
        totalMinutes += project.wins[w].session.final_par;
    }

    var totalCompletedMinutes = 0;
    var completedSessions = project.sessions.filter(ss => ss.status === "Completed");
    for (var s in completedSessions) {
        totalCompletedMinutes += completedSessions[s].completed_par;
    }

    return (
        <div className={className} >

            <div className='head'>
                <div className="title">{project.title}</div>
                <div className="tools">
                    <ProjectMenu project={project}></ProjectMenu>
                </div>
            </div>

            <WizardPop name={'welcome'} isOpen={isWelcomePopOpen} onClose={handleCloseWelcomePop} shift={[-36, 32]}></WizardPop>
            <WizardPop name={'thoughts'} isOpen={isThoughtsPopOpen && user.context.Wizard.nextStep === "thoughts"} onClose={handleCloseThoughtsPop}  shift={[-86, 162]}></WizardPop>
            <WizardPop name={'completeProject'} isOpen={isCompleteProjectPopOpen && user.context.Wizard.nextStep === "completeProject" && user.context.Wizard.completeProject !== 1} onClose={handleCloseCompleteProjectPop} shift={[-20, -20]}></WizardPop>

            <div className="HealthJourney">
                <div className="current-card">
                    {project.health.name ? 
                        <HealthCard project={project} isForGoal={false}></HealthCard> : 
                        <BlankHealthCard project={project}></BlankHealthCard>}
                    
                </div>
            </div>  

            <WizardPop name={'projects'} isOpen={isProjectPopOpen} onClose={handleCloseProjectsPop}  shift={[-36, 12]}></WizardPop>

            {/* <div class='health-grid'>
                <HealthGrid></HealthGrid>
            </div>       */}

            <div className='todays-sessions'>

                <WizardPop name={'sessions'} isOpen={isSessionsPopOpen} onClose={handleCloseSessionsPop} shift={[-36, 32]}></WizardPop>
                <WizardPop name={'impact-sessions'} isOpen={isImpactSessionsPopOpen} onClose={handleCloseImpactSessionsPop} shift={[-116, 4]}></WizardPop>
                <WizardPop name={'growth-sessions'} isOpen={isGrowthSessionsPopOpen} onClose={handleCloseGrowthSessionsPop} shift={[-116, 52]}></WizardPop>
                <WizardPop name={'ease-sessions'} isOpen={isEaseSessionsPopOpen} onClose={handleCloseEaseSessionsPop} shift={[-116, 102]}></WizardPop>
                <WizardPop name={'first-session'} isOpen={isFirstSessionPopOpen} onClose={handleCloseFirstSessionPop} shift={[-186, 4]}></WizardPop>

                <div className='sessions-content'>

                    <div className='daily-sessions-content'>

                        {console.log('placeholderSessions', project.title, placeholderSessions)}

                        {placeholderSessions.map(session => <SessionCard session={session} project={project} key={session.rank} onNewWin={onNewWin}></SessionCard>)}
                        <div className='clearfix'></div>
                    </div>

                    <div className='bonus-session-content'>
                        
                    </div>

                    <div className='done-session-content'>
                        <div className='ruby-item'>
                            <img src="/flex.png" className='item-img' />
                            {/* <div className='item-number'>25m</div> */}
                            <div className='item-label'>Great work!</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='project-total'>
            <span className="sessions-count">4 sessions</span> &nbsp; 61 minutes
            </div> */}


            <div className='project-goal'>

                <div className='all-time-content'>

                {/* <div className='all-time-day'>
                    Day 1
                </div> */}

                <div className='all-time-tabs'>
                    <div className='tab'>
                        <div className='value'></div>
                        <div className='label'>Total</div>
                    </div>
                    <div className='tab'>
                        <div className='value'></div>
                    </div>
                </div>

                </div>

                <div className='today-label'>
                    Today
                </div>
                <div className="today-widget today-project-widget">
                            {/* <img src="/peak.png" className='peak-img' /> */}

                        {totalCompletedMinutes >= project.today_goal && (
                            <div className='circle-done'>
                                <img src="project-check3.svg" className='done-img' />
                            </div>
                        )}

                        {totalCompletedMinutes < project.today_goal && (
                            <div className="circle">
                                <CircularProgressbar
                                value={totalCompletedMinutes > 0 ? totalCompletedMinutes / project.today_goal * 100 : 0}
                                text={""}
                                styles={buildStyles({
                                    pathColor: "#fac655",
                                    trailColor: "#fff",
                                    textSize: '12px',
                                    textColor: "#fff"
                                })}
                                strokeWidth={12}
                            />
                        </div>
                        )}

                            

                            <div className="minutes">{UI.formatMinutes(totalCompletedMinutes)} <span className="minutes-total">/ {project.today_goal}m</span></div>

                        </div>
            </div>

            {/* <div className="project-grades">
                <div className="ProjectGrade">
                    <div className="heading">
                        Project Speed
                    </div>
                    <div className="legend">
                        <div className='legend-icon legend-icon-check'>
                        <div className={`time-box`}></div>
                        </div>
                        <div className='legend-icon legend-icon-coin'>
                            <img src="/star.svg" className="legend-icon-img" />
                        </div>
                    </div>
                    <div className="progress">
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{width: 200 + 'px'}}></div>
                        </div>
                    </div>
                </div>

                <div className="ProjectGrade Confidence">
                    <div className="heading">
                        Project Confidence
                    </div>
                    <div className="legend">
                        <div className='legend-icon legend-icon-check'>
                            <div className={`time-box`}></div>
                        </div>
                        <div className='legend-icon legend-icon-coin'>
                            <img src="/star.svg" className="legend-icon-img" />
                        </div>
                    </div>
                    <div className="progress">
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{width: 120 + 'px'}}></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const wins = [
    {
        id: 1,
        label: "",
        healthName: "game",
        rankCategory: "Growth",
        createdAt: null
    },
    {
        id: 2,
        label: "",
        healthName: "game",
        rankCategory: "Impact",
        createdAt: null
    },
    {
        id: 3,
        label: "",
        healthName: "game",
        rankCategory: "Joy",
        createdAt: null
    },
    {
        id: 4,
        label: "",
        healthName: "game",
        rankCategory: "Impact",
        createdAt: null
    }
]

export default ProjectCard;