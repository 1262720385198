import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import Charity from './Charity';
import './DonationDrawer.scss';

function DonationDrawer({user, changeUser}) {

    const charities = [
        {
            name: "SaveTheChildren",
            label: "Save The Children Int."
        },
        {
            name: "Nature",
            label: "The Nature Conservancy"
        },
        {
            name: "AmericanCancerSociety",
            label: "American Cancer Society"
        },
        {
            name: "unicef",
            label: "UNICEF, for every child"
        }
    ]

    const ref = useRef();

    function handleCloseClick(event) {
        user.donationDrawer = null;
          changeUser({...user});
          event.stopPropagation();
      }

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            user.donationDrawer = null;
            changeUser({...user});
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", (e) => handleClickOutside(e));
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", (e) => handleClickOutside(e));
        };
      }, [ref, user, changeUser]);

    let className = 'DonationDrawer drawer';

    if (!user) {
        return (<div></div>);
    }

    if (!user.donationDrawer) {
        return (<div></div>);
    }

    const parentStyles = {};
    parentStyles.right = '0px';

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>
            <div className='big-subheading'>Manage Donations</div>
            <div className='big-heading'>
                Work Well. &nbsp;Do Good.
            </div>
            <div className='small-heading'>
                100% of profits are reserved for fulfilling 
                all<br />donations earned by our community.
            </div>
            {/* <div className='small-more'>
                <b>fun at work</b> is ran by one person + dedicated to minimal expenses.
            </div> */}

            <div className='your-total'>
                <div className="label">Your total earnings:</div>
                <div className="amount">$0</div>
            </div>

            <div className='rewards-subheading'>
                Charities & Causes
            </div>

            <div className="charities">
                {charities.map(charity => <Charity charity={charity} key={charity.name}></Charity>)}
            </div>

            <div className="more-label">
                More charities coming on January 1, 2023!
            </div>

        </div>
    )
};

export default DonationDrawer;