import logo from './logo.svg';
import './App.scss';
import './Mobile.scss';
import ProjectsScreen from './screens/ProjectsScreen';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import toast, { Toaster } from 'react-hot-toast';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import HealthGridDrawer from './components/HealthGridDrawer/HealthGridDrawer';
import { useEffect, useState } from 'react';
import Back from './helpers/Back';
import UserContext from './UserContext';
import CompleteProjectDrawer from './components/CompleteProjectDrawer/CompleteProjectDrawer';
import UI from './helpers/UI';
import BonusBar from './components/BonusBar/BonusBar';
import PushNotification from './helpers/PushNotification';
import CompleteDayDrawer from './components/CompleteDayDrawer/CompleteDayDrawer';
import WizardPop from './components/Wizard/WizardPop';
import Cookies from 'js-cookie';
import DonationDrawer from './components/DonationPage/DonationDrawer';
import SettingsDrawer from './components/SettingsDrawer/SettingsDrawer';
import Mast from './components/Mast/Mast';

function App() {

    useEffect(
        () => {
          const searchParams = new URLSearchParams(window.location.search.slice(1));
          if (searchParams.get("gate")) {
            Cookies.set("auth-token", searchParams.get("gate"));
            window.location.replace(window.location.origin);
          }

            loadUser();
        }, []
    );

    const loadUser = async () => {
        const response = await Back.user();
        console.log('loadUser', response);

        const user = response;

        user.showRankChangeId = {
            'Impact': 0, 'Growth': 0, 'Joy': 0
        };
        user.showRankChangeNumber = {
            'Impact': 0, 'Growth': 0, 'Joy': 0
        };

        user.showCoinCongratsId = {};

        setUser(user);

        window.Beacon('identity', {
          email: user.email
        });
        window.Beacon('prefill', {
          email: user.email
        });
    }

    const [user, setUser] = useState(null);
    
    function changeUser(user) {
        setUser(user);
        console.log('change user answer', user);
    }

    function givePoints(points) {
      for (var rankName in points) {
        const pointsToGive = points[rankName];

        const rank = user.ranks.find(r => r.name === rankName);
        const rankIndex = user.ranks.findIndex(r => r.name === rankName);

        if (rank.points + pointsToGive >= rank.goalPoints) {
          var finalPoints = rank.points + pointsToGive - rank.goalPoints;
          rank.points = rank.goalPoints + 1;
          user.ranks[rankIndex] = {...rank};
          changeUser({...user});

          setTimeout(() => {
              rank.level += 1;
              rank.points = 0;
              user.ranks[rankIndex] = {...rank};
              user.ruby_count += 1;

              user.session_level_ups += 1;
              user.showCoinChangeId = Math.random();
              user.showCoinChangeNumber = 1;

              user.ranks_needed_for_project_slot -= 1;

              changeUser({...user});
              UI.toastRankUp(rank.name, rank.level);
          }, 1000);

          setTimeout(() => {
              rank.points = finalPoints;
              rank.goalPoints = rank.level * 10 + 50;
              user.ranks[rankIndex] = {...rank};
              changeUser({...user});
          }, 1500);
        } else {
          rank.points += pointsToGive;
        }

        user.showRankChangeId[rankName] = Math.random();
        user.showRankChangeNumber[rankName] = pointsToGive;
      }

      changeUser({...user});
    }

    function giveHealthPoints(points, project) {
      project.health.points += points;

      if (project.health.points >= project.health.goalPoints) {

      }
    }

  return (
    <div className="App">
      <UserContext.Provider value={{user, changeUser, givePoints, giveHealthPoints}}>
        <DndProvider backend={HTML5Backend}>

        
          
          {/* <button id="enable">Enable notifications</button> */}
          <ProjectsScreen></ProjectsScreen>
          <CompleteProjectDrawer user={user} changeUser={changeUser} givePoints={givePoints}></CompleteProjectDrawer>
          <CompleteDayDrawer user={user} changeUser={changeUser} givePoints={givePoints}></CompleteDayDrawer>
          <SettingsDrawer user={user} changeUser={changeUser} givePoints={givePoints}></SettingsDrawer>
          <DonationDrawer user={user} changeUser={changeUser}></DonationDrawer>
          <HealthGridDrawer></HealthGridDrawer>
          <Toaster 
            position='bottom-left'
          />

          <div className='MobilePrompt'>
            <Mast></Mast>
            <img src="laptop.svg" className="laptop-img" />
            <div className="heading">
              This tool is designed to be used on your laptop or desktop computer.
            </div>
            <div className="more">
              You can visit <b>funatwork.co</b> on your computer and click "Log In" on our homepage.
            </div>
          </div>
          
        </DndProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
