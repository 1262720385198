import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './HealthCard.scss';

function HealthCard({project, isForGoal}) {

    const health = isForGoal ? project.goalHealth : project.health;

    var progressPercentage = 0;
    if (health.points > 0) {
        progressPercentage = (health.points / health.goalPoints) * 78;
    }

    return (
        <div className="HealthCard">
            <div className="icon">
                <img src={'health/' + health.name.toLowerCase() + '.png'} alt="Health" className="icon-img" />
            </div>
            <div className="content">
                <div className='health-label' dangerouslySetInnerHTML={{ __html: health.label }}>
                    
                </div>
                <div className='health-since'>
                    {isForGoal && <div>Goal</div>}
                    {!isForGoal && (
                        <div className='health-progress-bar'>
                            <div className='health-progress-fill' style={{width: progressPercentage + 'px'}}></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HealthCard;