import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import './SettingsDrawer.scss';

import Cookies from 'js-cookie';

function SettingsDrawer({user, changeUser, givePoints}) {

    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    function handleCloseClick() {
        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.settingsDrawer = null;
            changeUser({...user});
            setLockStyle(false);
        }, 1000);
    };

    const handleLogOutClick = (e) => {
        Cookies.remove("auth-token");
        window.location.href = "https://funatwork.co";
    }

    const handleContactSupportClick = (e) => {
        //window.location.href = "mailto:support@funatwork.co";
        window.Beacon('open');
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              handleCloseClick();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, user, changeUser]);

    let className = 'SettingsDrawer drawer';

    if (!user) {
        return (<div></div>);
    }
    if (!user.settingsDrawer) {
        return (<div></div>);
    }

    if (!lockStyle && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const parentStyles = {};
    parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    parentStyles.right = opacity === "0.5" ? "-480px" : "0px" 

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>

            <div className="heading">Settings</div>
            
            <div className="options">
                <div className="item" onClick={handleLogOutClick}>Log Out</div>
                <div className="item" onClick={handleContactSupportClick}>Contact Support</div>
            </div>
        </div>
    )
};

export default SettingsDrawer;