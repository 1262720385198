import React from 'react';
import './RankDashboard.scss';
import RankWidget from './RankWidget';

function RankDashboard({project}) {

    const widgets = [
        {
            name: "Impact",
            label: "Impact",
            rank: 1,
            progress: 80,
            primaryColor: "#ffa26f",
            lightColor: "#ffe2c0"
        },
        {
            name: "Growth",
            label: "Growth",
            rank: 2,
            progress: 70,
            primaryColor: "#24d138",
            lightColor: "#97eda1"
        },
        {
            name: "Joy",
            label: "Ease",
            rank: 3,
            progress: 60,
            primaryColor: "#d46ce9",
            lightColor: "#f0caf8"
        },

    ];

    return (
        <div className="RankDashboard">
            <div className='widgets'>
                {widgets.map(widget => <RankWidget widget={widget} key={widget.name}></RankWidget>)}
            </div>
        </div>
    )
}

export default RankDashboard;