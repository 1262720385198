import { useRef, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'

function WizardPop({name, isOpen, onClose, shift, positions}) {

    const clickMeButtonRef = useRef();
    const [isPopoverOpen, setIsPopoverOpen] = useState(true);

    const placeholderStyle = {};

    if (shift) {
        placeholderStyle.position = 'relative';
        placeholderStyle.left = shift[0] + 'px';
        placeholderStyle.top = shift[1] + 'px';
    }

    if (!positions) {
        positions = ['right', 'bottom'];
    }

    return (
        <div>
            {/* <div ref={clickMeButtonRef}>Click Me</div> */}
            <Popover
                isOpen={isOpen}
                positions={positions}
                padding={10}
                onClickOutside={() => {
                    onClose(); }}
                ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#ffd3a8'}
                    arrowSize={10}
                    arrowStyle={{ opacity: 1}}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                    >
                    <div
                        style={{ backgroundColor: '#ffd3a8', border: '0px solid #fff', borderRadius: 12, boxShadow: '3px 3px 6px 1px #ddd' }}
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                        <div className='WizardContent'>
                            {content[name]}
                            <div className='next-button' onClick={() => onClose()}>
                                Got it!
                            </div>
                        </div>
                    </div>
                    </ArrowContainer>
                )}
                >
                <div className='Wizard-placeholder' style={placeholderStyle}></div>
            </Popover>
        </div>
    )
}

const content = {
    'welcome': (
        <div className='intro'>
            <p>
                <b>Welcome!</b>
            </p>
            <p>
            Here's a <u>practice project</u> we created to 
                show you how everything works.
            </p>
        </div>
    ),
    'projects': (
        <div className='intro'>
            <p>
                You probably <i>already</i> organize your work elsewhere. 
            </p>
            <p>
                So think of here as your <u>own space</u> to chunk out <b>important</b> work to focus on.
            </p>
            <p>
                Creating projects that will take <u>2 to 10 hours</u> to complete is recommended.
            </p>
        </div>
    ),
    'sessions': (
        <div className='intro'>
            <p>
                Each day you'll complete focused sessions 
                on your projects.
            </p>
            <p>
                There are 3 types of sessions: <b>Impact</b>, <b>Growth</b>, and <b>Ease</b>.
            </p>
        </div>
    ),
    'impact-sessions': (
        <div className='intro'>
            <p>
                <b>Impact sessions</b> are lengthy sessions of work 
                for when you feel confident or energized about diving into a project 
                and really moving it forward.
            </p>
            <p>
                These sessions are in <b style={{color: "#f47129"}}>orange</b> on the first line.
            </p>
        </div>
    ),
    'growth-sessions': (
        <div className='intro'>
            <p>
                <b>Growth sessions</b>  are shorter sessions 
                that are great for making progress on a project 
                even if you're a little low on energy or focus.
            </p>
            <p>
                They're also great for stretching your ability 
                to focus over time.
            </p>
            <p>
                They're in <b style={{color: "#0bac1e"}}>green</b> on the second line.
            </p>
        </div>
    ),
    'ease-sessions': (
        <div className='intro'>
            <p>
                <b>Ease sessions</b> are very short sessions. 
                Use these sessions when you need small wins 
                on a project to gain confidence or ease into it.
            </p>
            <p>
                These sessions also help ease your 
                anxiety or negative thinking towards work over time.
            </p>
            <p>
                They're in <b style={{color: "#8a14a2"}}>purple</b> on the last line.
            </p>
        </div>
    ),
    'first-session': (
        <div className='intro'>
            <p>
                Go ahead and start a session by hovering over a line and choosing an option.
            </p>
            <p>
                <u>Don't worry about doing actual work</u>, it's just for practice. We'll end it early.
            </p>
        </div>
    ),
    'started-session': (
        <div className='intro'>
            <p>
                <u>Your browser may ask if you want notifications enabled above</u>. We only use this to let you know when your sessions end.
            </p>
            <p>
                Once this practice session finishes, you can click <b>Done</b>!
            </p>
        </div>
    ),
    'started-session-2': (
        <div className='intro'>
            <p>
                As you work, you'll gain experience in <u>three areas</u>. 
            </p>
            <p>
                Each time you fill a circle here, you'll earn a <b>coin</b> below.
            </p>
            <p>
                For every 15 coins you earn, you can choose a charity to earn a&nbsp; 
                <u>$20 donation</u> for!
            </p>
        </div>
    ),
    'thoughts': (
        <div className='intro'>
            <p>
                After each session, you'll take a second to <u>choose a positive thought</u> that <b>feels true</b> about the work you've just done.
            </p>
            <p>
                This may feel a little <i>funny</i> <u>at first</u>, 
                but you'll notice a subtle shift in how 
                you view work over time.
            </p>
        </div>  
    ),
    'completeProject': (
        <div className='intro'>
            <p>
                <b>Great work!</b> We can trash this practice project now 
                so you can move on to your own projects. Click the <u>three dots</u> to do so.
            </p>
            <p>
                (We'll point out a few things later, but 
                for now we'll let you work!)
            </p>
        </div>  
    )
}

export default WizardPop;