function askNotificationPermission() {

    var notificationBtn = window.document.getElementById('enable');

    // function to actually ask the permissions
    function handlePermission(permission) {
      // set the button to shown or hidden, depending on what the user answers
      // notificationBtn.style.display =
      //   Notification.permission === 'granted' ? 'none' : 'block';
    }
  
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log("This browser does not support notifications.");
    } else if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        console.log('permission', permission);
        handlePermission(permission);
      });
    } else {
      Notification.requestPermission((permission) => {
        console.log('permission2', permission);
        handlePermission(permission);
      });
    }
  }

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
  
    return true;
  }

  export default {
      askNotificationPermission
  };

