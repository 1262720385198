import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import GemNotch from './GemNotch';
import './ProjectGem.scss';

import Countdown from 'react-countdown';

function ProjectGem({gem, project, onOverSession, onOutSession, onSelectedSession}) {

    const notchCount = 4;
    const notchesDone = 2;

    const initialNotches = [];

    for (var n = 0; n < notchCount; n++) {

        const isDone = n < notchesDone;

        initialNotches.push({
            position: n,
            status: isDone ? "Completed" : "Pending"
        });
    }

    const [notches, setNotches] = useState(initialNotches);
    const [overSession, setOverSession] = useState(null);
    const [timerStatus, setTimerStatus] = useState("Pending");
    const [timerStart, setTimerStart] = useState(Date.now() + 1000);

    const onParPassed = () => {
        setTimeout(() => {
            setTimerStatus('ParPassed');
        }, 1000)
    };

    const startSession = (notch) => {
        
        const par = 8 * (notch.position - notchesDone + 1);
        var sessionLength = (par * 1000 * 60);
        if (process.env.REACT_APP_SHORT_SESSIONS === "1") {
           sessionLength = 2000;
        }
        var date = Date.now() + sessionLength;

        setTimeout(() => {
            var notification = new Notification(
                "You're done!",
                {
                    body: "You completed your session. Nice work!",
                    icon: "health/" + project.health.name.toLowerCase() + ".png"
                }
            );
            notification.onclick = function() {
                window.parent.focus();
                notification.close();
            };
        }, sessionLength + 2000)

        setTimerStart(date);
        setTimerStatus('InProgress');
    }

    const handleClickNotch = (notch) => {
        

        startSession(notch);
    };

    const handleOverNotch = (notch) => {
        if (timerStatus !== "Pending") {
            return;
        }

        console.log('start newNotches');
        const lastPosition = notch.position;

        const newNotches = [];

        for (var n = 0; n < notchCount; n++) {
            const item = notches[n];

            if (item.status === "Pending" && item.position <= lastPosition) {
                item.is_touched = true;
                console.log('end', item.position, lastPosition);
                if (item.position === lastPosition) {
                    item.is_touched_end = true;
                } else {
                    item.is_touched_end = false;
                }
            } else {
                item.is_touched = false;
                item.is_touched_end = false;
            }

            newNotches.push(item);
            console.log(item.position + ':' + item.is_touched + ':' + item.status + ':' + lastPosition);
        }

        setNotches([...newNotches]);

        console.log('setOverSession', lastPosition, notchesDone);
        setOverSession(8 * (lastPosition - notchesDone + 1));

        onOverSession(1);
    };
    const handleOutNotch = (notch) => {
        if (timerStatus !== "Pending") {
            return;
        }

        onOverSession(null);
        onOutSession(1);
    };

    const handleTotalLeave = () => {
        if (timerStatus !== "Pending") {
            return;
        }

        const newNotches = [];

        for (var n = 0; n < notchCount; n++) {
            const item = notches[n];

            item.is_touched = false;
            item.is_touched_end = false;

            newNotches.push(item);
        }

        setOverSession(null);
        setNotches([...newNotches]); 
    }

    const handleDoneClick = async () => {

    };

    return (
        <div className="ProjectGem" onMouseLeave={handleTotalLeave}>
            <div className="icon">
                <img src={gem.name.toLowerCase() + '.png'} alt="Gem" className="icon-img" />
            </div>
            <div className="notch-bar">
                 {notches.map(notch => <GemNotch notch={notch} gem={gem} onClick={handleClickNotch} onOver={handleOverNotch} onOut={handleOutNotch} key={notch.position}></GemNotch>)}
            </div>

            <div className="fill-position">
                <div className="fill-bar" style={{width: 27 * notchesDone + 'px', backgroundImage: 'url(gem-filled-bar.png)'}}></div>
                <img src="gem-notch-filled3.png" style={{left: -172 + (27 * notchesDone) + 'px'}} className="fill-notch" />
                &nbsp;
            </div>

            {overSession && (
                <div className="session-position">
                    <div className="session">
                        {timerStatus === "Pending" && <div className="time-label">{overSession} minutes</div>}
                        {timerStatus === 'ParPassed' && (
                            <div className='done-content'>
                                <div className='completed-label'>Completed!</div>
                                <div className='done-button' onClick={handleDoneClick}>Done</div>
                            </div>
                        )}
                        {timerStatus === "InProgress" && (
                            <Countdown 
                                 date={timerStart} 
                                 onComplete={onParPassed}
                                 overtime={false}
                                 renderer={renderer}>
                             </Countdown>
                        )}
                                 
                    </div>
                </div>
            )}
        </div>
    )
}

const renderer = ({ hours, minutes, seconds, completed }) => {

    if (minutes === 0 && seconds === 0) {
        completed = false;
    }

    minutes = minutes + '';
    seconds = seconds + '';

    if (minutes.length === 1) {
        minutes = '0' + minutes;
    }
    if (seconds.length === 1) {
        seconds = '0' + seconds;
    }

    var classes = 'count';

    var prefix = '';
    if (completed) {
        prefix = '+';
        classes += ' count-completed';
    }

    return <div className={classes}>{prefix}{minutes}:{seconds}</div>;
  };

export default ProjectGem;