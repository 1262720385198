import React, { useContext, useState } from 'react';
import './SessionCard.scss';
import Countdown from 'react-countdown';
import ChooseThought from '../ChooseThought/ChooseThought';

import { animated, useSpring, config, useTransition } from '@react-spring/web'
import UserContext from '../../UserContext';
import { useMemo } from 'react';
import Confetti from 'react-dom-confetti';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import PushNotification from '../../helpers/PushNotification';
import WizardPop from '../Wizard/WizardPop';

function SessionCard({project, session, onStarted, onCompleted, onNewWin}) {
    
    const [fireConfetti, setFireConfetti] = useState(false);

          const confettiConfig = {
            angle: 45,
            spread: 55,
            startVelocity: 52,
            elementCount: 30,
            dragFriction: 0.23,
            duration: 1600,
            stagger: 3,
            width: "8px",
            height: "8px",
            perspective: "600px",
            colors: ["#ff9f2e", "#13c828", "#cb4ce5"]
          };
          const confettiConfigLeft = {
            angle: 135,
            spread: 55,
            startVelocity: 52,
            elementCount: 30,
            dragFriction: 0.23,
            duration: 1600,
            stagger: 3,
            width: "8px",
            height: "8px",
            perspective: "600px",
            colors: ["#ff9f2e", "#13c828", "#cb4ce5"]
          };

    const [par, setPar] = useState(session.par);
    // const [finalPar, setFinalPar] = useState(session.final_par);
    const [status, setStatus] = useState(session.status);
    const [timerStatus, setTimerStatus] = useState('NotStarted');
    const [timerStart, setTimerStart] = useState(Date.now() + 1000);

    const [isOverStretch, setIsOverStretch] = useState(false);
    const [isOverEasy, setIsOverEasy] = useState(false);

    const [showHoverButtons, setShowHoverButtons] = useState(false);
    const [hoverDifficulty, setHoverDifficulty] = useState(null);
    const [hoverDifficultyPar, setHoverDifficultyPar] = useState(null);

    const [doneButtonScale, setDoneButtonScale] = useState('1');
    const [inProgressContentOpacity, setInProgressContentOpacity] = useState(1);
    const [selectThoughtContentOpacity, setSelectThoughtContentOpacity] = useState(0);

    const [isExpanded, setIsExpanded] = useState(false);

    const {user, changeUser, givePoints} = useContext(UserContext);

    const [shownCoinCongratsId, setShownCoinCongratsId] = useState(null);
    const [showCoinCongrats, setShowCoinCongrats] = useState(false)
    const [coinCongratsTransitionItems, setCoinCongratsTransitionItems] = useState([]);
    const coinCongratsTransitions = useTransition(coinCongratsTransitionItems, {
        from: { opacity: 0, y: -20 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 12 },
        reverse: showCoinCongrats,
        delay: 550,
        config: config.stiff,
        onRest: () => {
            setTimeout(() => {
                setCoinCongratsTransitionItems([])
            }, 3000);
        }
    });
    //console.log('showCoinCongratsId', user.showCoinCongratsId);

    const handleOverDifficultyButton = (diff) => {

        if (user.activeSession) {
            return;
        }

        if (diff === "Standard") {
            if (session.rank === "Impact") {
                diff = "ImpactStandard";
            }
            if (session.rank === "Growth") {
                diff = "GrowthStandard";
            }
            if (session.rank === "Joy") {
                diff = "JoyStandard";
            }
        }
        if (diff === "Difficulty1") {
            if (session.rank === "Impact") {
                diff = "Stretch";
            }
            if (session.rank === "Growth") {
                diff = "Grow";
            }
            if (session.rank === "Joy") {
                diff = "Expand";
            }
        }
        if (diff === "Difficulty2") {
            if (session.rank === "Impact") {
                diff = "Push";
            }
        }

        const pars = {
            "ImpactStandard": 20,
            "Stretch": 25,
            "Push": 30,
            "GrowthStandard": 10,
            "Grow": 15,
            "JoyStandard": 6,
            "Expand": 8
        };

        setHoverDifficulty(diff);
        setHoverDifficultyPar(pars[diff]);
        console.log('set par', pars[diff]);
    };

    const difficultyLabels = {
            "ImpactStandard": "Start",
            "Stretch": "Stretch",
            "Push": "Push",
            "GrowthStandard": "Start",
            "Grow": "Grow",
            "JoyStandard": "Start",
            "Expand": "Expand"
    };

    const [showSelectedThoughtCompletion, setShowSelectedThoughtCompletion] = useState(false);

    const [isStartedSessionPopOpen, setIsStartedSessionPopOpen] = useState(true);
    const [isStartedSession2PopOpen, setIsStartedSession2PopOpen] = useState(2);

    if (! session) {
        console.log('no session in props', project, user);
        return;
    }

    const startSession = () => {

        if (user.activeSession) {
            return;
        }

        if (!project.health.name) {
            UI.showWarningMessage(
                <span>Choose a new <b>Project Stage</b> for your project before starting a session.</span>
            );
            return;
        }

        session.par = hoverDifficultyPar;
        session.final_par = hoverDifficultyPar;
        setFinalPar(hoverDifficultyPar);

        user.activeSession = session;
        changeUser({...user});

        setTimeout(() => {
            setIsStartedSessionPopOpen(true);
        }, 100);

        const encouragementContent = (
            <div className='coin-congrats-content'>
                <img src="/flex.png" className='flex-img' />
                <div className='encouragement-label'>You've got this!</div>
                
                {/* <div className='minutes-label'>+2 minutes</div> */}
                
            </div>
        );
        setCoinCongratsTransitionItems([encouragementContent]);

        PushNotification.askNotificationPermission();

        
        var sessionLength = (finalPar * 1000 * 60);
        if (process.env.REACT_APP_SHORT_SESSIONS === "1") {
           sessionLength = 1000;
        }
        console.log('wizard', session.is_for_wizard && !user.context.Wizard.sessionStarted, session, user.context);
        if (!user.context.Wizard.startedSession) {
            sessionLength = 5000;
        }
        var date = Date.now() + sessionLength;

        setTimeout(() => {
            var notification = new Notification(
                "You're done!",
                {
                    body: "You completed your session. Nice work!",
                    icon: "health/" + project.health.name.toLowerCase() + ".png"
                }
            );
            notification.onclick = function() {
                window.parent.focus();
                notification.close();
            };
        }, sessionLength + 1000)

        setTimerStart(date);
        setStatus('InProgress');

        //const newUser = {...user};

        // user.showCoinChangeId = Math.random();
        // user.showCoinChangeNumber = 1;
        // changeUser({...user});

        // console.log('new rank', impactRank);

        // newUser.ranks[impactRankIndex] = {...impactRank};
        // console.log('new user', newUser);
        // changeUser({...newUser});
        
    };

    const onParPassed = () => {
        setTimeout(() => {
            setTimerStatus('ParPassed');

            //new Audio('success.mp3').play()

            // var notification = new Notification(
            //     "You're done!",
            //     {
            //         body: "You completed your session. Nice work!",
            //         icon: "health/" + project.health.name.toLowerCase() + ".png"
            //     }
            // );
            // notification.onclick = function() {
            //     window.parent.focus();
            //     notification.close();
            // };
            // console.log('not', notification);
        }, 1000)
    };

    const onDone = () => {
        setFireConfetti(true);
        setDoneButtonScale(0);

        setTimeout(() => {
            setIsStartedSession2PopOpen(1);
            user.context.Wizard.nextStep = "startedSession2";
        }, 2000);
       
        setTimeout(() => {
            setInProgressContentOpacity(0);
        }, 900);

        setTimeout(() => {
            setStatus('SelectThought');
            setIsExpanded(true);

            setSelectThoughtContentOpacity(1);
        }, 1500);

        // const growthRank = user.ranks.find(r => r.name === 'Growth');
        // const growthRankIndex = user.ranks.findIndex(r => r.name === 'Growth');
        // growthRank.points += session.par;

        // const joyRank = user.ranks.find(r => r.name === 'Joy');
        // const joyRankIndex = user.ranks.findIndex(r => r.name === 'Joy');
        // joyRank.points += 5;

        // user.ranks[growthRankIndex] = {...growthRank};
        // user.ranks[joyRankIndex] = {...joyRank};
        // user.showRankChangeId['Growth'] = Math.random();
        // user.showRankChangeNumber['Growth'] = session.par;
        // user.showRankChangeId['Joy'] = Math.random();
        // user.showRankChangeNumber['Joy'] = 5;
        // changeUser({...user});

        const points = {};
        points[session.rank] = finalPar;

        if (session.rank === "Joy") {
            points[session.rank] += 2;
        }

        givePoints(points);
    };

    

    const onThoughtFilling = (thought) => {
        setTimeout(() => {
            setShowSelectedThoughtCompletion(true);
        }, 1800);
    };

    const onThoughtSelected = async (thought) => {

        setStatus('Pending');
        setTimerStatus("NotStarted");
        setInProgressContentOpacity('1');
        setSelectThoughtContentOpacity('1');
        setDoneButtonScale('1');
        setFireConfetti(false);
        setShowHoverButtons(false);

        const newWin = {
            id: 123123,
            label: thought.label,
            healthName: project.health.name,
            rankCategory: thought.type,
            session: session,
            createdAt: null
        };

        const projectIndex = user.projects.findIndex(p => p.id === session.project_id);
        project.wins.unshift(newWin);

        project.health.points += session.par;
        if (project.health.points > project.health.goalPoints) {
            project.health.points = project.health.goalPoints;
        }

        const data = await Back.completeSession(session.id, project.id, session.par, session.par, session.difficulty, session.rank, thought.label);
        console.log('completeSession response', data);

        user.projects[projectIndex] = {...data.project};

        if (user.context.Wizard.nextStep === "thoughts") {
            user.context.Wizard.nextStep = "completeProject";
        }

        user.today_total_work_minutes = data.user.today_total_work_minutes;

        changeUser({...user});

        //session.status = 'Completed';
        onNewWin(newWin, user, session);

        console.log('health data', data);

        if (data.is_health_completed) {
            const message = (
                <span>You completed the <img src={'health/' + project.health.name.toLowerCase() + '.png'} className="image-phase" /><span className="level-phase">{project.health.label}</span> stage!</span>
            );
            console.log('health message', message);
            UI.toastMessageWithEarnings(message, null, null, null, 10, 10);

            setTimeout(() => {
                project.health.name = null;
                user.projects[projectIndex] = {...project};
                session.status = "Pending";
                setStatus('Pending');

                changeUser({...data.user});
            }, 1000);
        }

        if (data.is_today_completed) {
            user.completeDayDrawer = true;
            changeUser({...user});
        }

        if (data.is_project_goal_completed) {
            const goalMessage = (
                <span>You reached today's goal for <span className="level-phase">{project.health.label}</span>!</span>
            );
            UI.toastMessageWithEarnings(goalMessage, null, null, 10, 10, null);
        }

        setTimeout(() => {
            setShowHoverButtons(false);
            user.activeSession = null;
            changeUser({...user});
        }, 300);
    };

    const handleEnterHoverButtons = () => {
        // if (user.activeSession) {
        //     return;
        // }

        console.log('handleEnterHoverButtons');

        setShowHoverButtons(true);
    }
    const handleLeaveHoverButtons = () => {
        console.log('handleLeaveHoverButtons');
        setShowHoverButtons(false);
    }

    const handleMouseOverStretch = () => {
        if (session.difficulty !== "Stretch") {
            session.difficulty = "Stretch";
            //setFinalPar(session.par + 4);
            //finalPar = session.par + 4;
            setIsOverStretch(true);
        }
    };
    function handleMouseOutStretch() {
        if (session.difficulty === "Stretch") {
            session.difficulty = null;
            //setFinalPar(session.par);
            setIsOverStretch(false);
            console.log('stretch out', session);
        }
    };

    const handleMouseOverEasy = () => {
        if (session.difficulty !== "Stretch+") {
            session.difficulty = "Stretch+";
            setFinalPar(30);
            finalPar = 30;
            setIsOverEasy(true);
        }
    };
    function handleMouseOutEasy() {
        if (session.difficulty === "Dash") {
            session.difficulty = null;
            //setFinalPar(session.par);
            setIsOverEasy(false);
            console.log('stretch out', session);
        }
    };

    const handleCloseStartedSessionPop = () => {
        console.log('handleCloseStartedSessionPop', isStartedSessionPopOpen);
        setIsStartedSessionPopOpen(false);

        const response = Back.completeWizardStep("startedSession");
    };
    const handleCloseStartedSession2Pop = () => {
        //setIsStartedSession2PopOpen(false);
        console.log('handleCloseStartedSession2Pop', isStartedSession2PopOpen);
        setIsStartedSession2PopOpen(prev => prev - 1);

        const response = Back.completeWizardStep("startedSession2");

        setTimeout(() => {
            user.context.Wizard.nextStep = "thoughts";
            changeUser({...user});
        }, 100);
    };

    var content = null;
    var minutes = session.final_par;

    // if (session.difficulty === "Stretch") {
    //     minutes += 4;
    // }

    var finalPar = session.final_par;
    if (!finalPar) {
        finalPar = session.par;
    }
    var setFinalPar = (value) => {
        finalPar = value;
    }
    if (isOverStretch) {
        finalPar = 25;
    }
    if (session.difficulty === "Stretch+") {
        finalPar = 30;
    }
    if (isOverEasy) {
        //finalPar -= 4;
    }


    if (status === 'Pending') {
        
        let sessionTabClasses = "";
        const tabSessions = project.sessions.filter(s => s.status === "Completed" && s.rank === session.rank);
        
        let minutesWord = "minutes";
        if (tabSessions.length > 2) {
            minutesWord = "minutes";
            sessionTabClasses += " session-tab-condensed";
        }
        if (tabSessions.length > 3) {
            minutesWord = "min";
            sessionTabClasses += " session-tab-very-condensed";
        }
        
        content = (
            <div className='pending-content' onClick={startSession} onMouseEnter={handleEnterHoverButtons} onMouseLeave={handleLeaveHoverButtons}>

                
                    <div className='stretch-buttons-position'>
                    <div className='stretch-buttons'>

                         <div className='stretch-button' onMouseOver={() => handleOverDifficultyButton('Standard')} onMouseOut={null}>
                            
                            {session.rank === "Impact" && <img src="/impact-start.png" className="impact-start-img" />}
                            {session.rank === "Growth" && <img src="/growth-start.png" className="growth-start-img" />}
                            {session.rank === "Joy" && <img src="/joy-start.png" className="joy-start-img" />}
                            <div className="label">18</div>
                        </div>

                        <div className='stretch-button'  onMouseOver={() => handleOverDifficultyButton('Difficulty1')} onMouseOut={handleMouseOutStretch}>
                            <div className="label">20</div>
                            {session.rank === "Impact" && <img src="/stretch2f.png" className="stretch-img" />}
                            {session.rank === "Growth" && <img src="/grow-start.png" className="stretch-img" />}
                            {session.rank === "Joy" && <img src="/meditate.png" className="stretch-img" />}
                        </div>
                       
                       {session.rank === "Impact" && (
                        <div className='stretch-button sprint-button'  onMouseOver={() => handleOverDifficultyButton('Difficulty2')} onMouseOut={handleMouseOutEasy}>  
                            {session.rank === "Impact" && <img src="/sprint.png" className="stretch-img" />}
                            <div className="label">16</div>
                        </div>
                        )}

                    </div>
                        {hoverDifficulty && (
                            <div className='stretch-action-label'>
                                <div className="number">{hoverDifficultyPar}</div>
                                <div className="label">{difficultyLabels[hoverDifficulty]}</div>
                                {/* <span className="stretch-action-difficulty" style={{fontStyle: session.difficulty ? "normal" : "normal"}}>{difficultyLabels[hoverDifficulty]}</span><br /> {hoverDifficultyPar} */}
                            </div>
                        )}
                        
                    </div>
                

                    <div className='time-content'>
                        {session.is_bonus ? 
                            <div classsName="bonus-box"><img src={`/${session.bonus_item.name}-ice.png`} className="bonus-img" /></div> :
                            <div className={`time-box`}></div>
                        }

<div className="session-tab-new">
                            +
                        </div>

                        {
                            tabSessions
                                .map(s => (
                                    <div className={`session-tab ${sessionTabClasses}`}>
                                        {s.completed_par} {minutesWord}
                                    </div>
                                ))
                                
                        }

                        {project.id === 1 && session.rank === "Joy" && (
                            <div className="session-suggest-container">
                                {/* <div className="session-tab session-suggest">
                                        25 minutes
                                    </div> */}
                                <div className="session-tab session-suggest session-suggest-note">
                                    <img src="/burst.svg" className="icon-img" />
                                    <div className="label">8:00</div>
                                </div>
                            </div>
                        )}

                        {/* {session.id === 178 && (
                            <div className="session-person">
                                <img src="/person-brad.png" className="icon-img" />
                            </div>
                        )} */}
{/* 
                            <div className="session-gem">
                                <img src="/gem-green.png" className="icon-img" />
                            </div> */}

                        {/* <div className="session-tab">
                            20 minutes
                        </div>
                        <div className="session-tab">
                            25 minutes
                        </div>
                        <div className="session-tab session-tab-growth">
                            10 minutes
                        </div>
                        <div className="session-tab session-tab-joy">
                            6 minutes
                        </div> */}

                        
                        {/* <div className="session-tab">
                            20 minutes
                        </div> */}
                        {/* <div className="session-tab-total">
                            48
                        </div> */}

                        

                        
                        
                        {/* <div className='time-label'><span style={{fontStyle: isOverStretch || isOverEasy ? 'italic' : 'normal'}}>{finalPar}{session.is_bonus && <span className="time-bonus-max"> / 40</span>}</span> minutes</div> */}
                        {/* <div className='time-label'>&nbsp;{session.rank === "Impact" ? "" : "Growth"}</div> */}
                    </div>

                <div>
                    {coinCongratsTransitions((styles, item) => item && <animated.div style={{...styles}}>
                        {item}
                    </animated.div>)}
                </div>
                
            </div>
        )
    }
    if (status === 'InProgress') {
        content = (
            <div className='TimerWidget in-progress-content' style={{opacity: inProgressContentOpacity}}>

                {user.context.Wizard.nextStep === "startedSession" && <WizardPop name={'started-session'} positions={['bottom']} shift={[10, -198]} isOpen={isStartedSessionPopOpen} onClose={handleCloseStartedSessionPop}></WizardPop>}

                <div className={`time-box time-box-${minutes <= 10 ? 'growth' : 'impact'}`}></div>

                {timerStatus === 'ParPassed' ? <div className='completed-label'>Completed!</div> :
                                 <Countdown 
                                 date={timerStart} 
                                 onComplete={onParPassed}
                                 overtime={false}
                                 renderer={renderer}>
                             </Countdown>}

                <div className='confetti-container-left'>
                    <Confetti active={fireConfetti} config={ confettiConfigLeft } />
                </div>
                {timerStatus === 'ParPassed' && <div className='done-button' onClick={onDone} style={{transform: 'scale('+doneButtonScale+')', opacity: doneButtonScale}}>Done</div>}
                <div className='confetti-container-right'>
                    <Confetti active={fireConfetti} config={ confettiConfig} />
                </div>

                <div>
                    {coinCongratsTransitions((styles, item) => item && <animated.div style={{...styles}}>
                        {item}
                    </animated.div>)}
                </div>
            </div>
        );
    }
    if (status === 'SelectThought') {
        content = (
            <div className='select-thought-content' style={{opacity: selectThoughtContentOpacity}}>

                {showSelectedThoughtCompletion ? (
                    <div className='select-thought-completed-content'>
                        <img src="checkmark-green2.png" className='check-img' />
                        <div className='time-label'>{finalPar} minutes</div>
                    </div>
                ) : (
                    <div className='time-label time-label-great'>🙌&nbsp; Great job!</div>
                )}

                <ChooseThought session={session} project={project} onThoughtSelected={onThoughtSelected} onThoughtFilling={onThoughtFilling}></ChooseThought>
            </div>
        )
    }
    if (status === 'Completed') {

        content = (
            <div>
                {/* <div className='completed-border completed-border-first'></div> */}
            <div className='completed-content'>


                {session.leveled_up > 0 && (
                    <div className='coin-used-content'>
                        <img src="/peace.png" className='coin-used-img' />
                        <div className='coin-used-label'>+2</div>
                    </div>
                )}
                
            </div>
            <div className='completed-border'></div>
            </div>
        )
    }

    var className = 'SessionCard';
    className += ' ' + status;
    if (isExpanded) {
        className += ' expanded';
    }
    if (showHoverButtons && !user.activeSession) {
        className += ' ShowHoverButtons';
    }

    className += ' ' + session.rank;

    return (
        <div className={className}>
            {user.context.Wizard.startedSession2 !== 1 && user.context.Wizard.nextStep === "startedSession2" && <WizardPop name={'started-session-2'} positions={['left']} shift={[840, -6]} isOpen={isStartedSession2PopOpen} onClose={() => handleCloseStartedSession2Pop()}></WizardPop>}
            {content}
        </div>
    )
};

const renderer = ({ hours, minutes, seconds, completed }) => {

    if (minutes === 0 && seconds === 0) {
        completed = false;
    }

    minutes = minutes + '';
    seconds = seconds + '';

    if (minutes.length === 1) {
        minutes = '0' + minutes;
    }
    if (seconds.length === 1) {
        seconds = '0' + seconds;
    }

    var classes = 'count';

    var prefix = '';
    if (completed) {
        prefix = '+';
        classes += ' count-completed';
    }

    return <div className={classes}>{prefix}{minutes}:{seconds}</div>;
  };

export default SessionCard;