import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './Charity.scss';

function Charity({charity}) {

    return (
        <div className="Charity">
            <img src={`/charities/${charity.name.toLowerCase()}.jpg`} className="hero-img" />

            <div className='mast'>
                <img src={`/charities/${charity.name.toLowerCase()}-logo.jpg`} className="logo-img" />
                <div className="label">{charity.label}</div>
            </div>

            <div className='donate'>
                <div className='coin'>
                    <img src="/coin-shadow2.png" className="shadow-img" />
                    <img src="/star.svg" className="ruby-img" />  
                    <div className='amount'>10</div>
                </div>
                <div className='donat'>
                    $20
                </div>
            </div>
        </div>
    )
}

export default Charity;