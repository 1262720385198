import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ProjectStub from '../ProjectStub/ProjectStub';
import './Mast.scss';



function Mast({user, changeUser}) {

    const handleSettingsClick = (e) => {
        e.stopPropagation();

        user.settingsDrawer = true;
        changeUser({...user});
    }

    return (
        <div className="Mast">
            <div className='logo'>
                <img src="/logo-square.png" className='logo-img' />

                <div className="beta">Beta</div>
                <div className='tools'>
                    
                    <div className='settings' onClick={handleSettingsClick}>
                        <img src="settings2.svg" className='icon-img' />
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Mast;