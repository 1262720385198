import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import './BlankHealthCard.scss';

function BlankHealthCard({project}) {

    const {user, changeUser} = useContext(UserContext);

    const handleClick = () => {
        user.healthGridDrawer = project;
        user.healthGridDrawerOnSelection = async (item) => {
            const savedProject = await Back.startProjectStage(
                project.id, item.name
            );
            console.log('savedProject', savedProject);

            project.health = savedProject.health;
            project.today_goal = savedProject.today_goal;

            const projectIndex = user.projects.findIndex(p => p.id === project.id);
            user.projects[projectIndex] = project;
            user.healthGridDrawer = null;

            changeUser({...user});

            const messageContent = (
                <span>You started the <img src={'health/' + project.health.name.toLowerCase() + '.png'} className="image-phase" /><span className='level-phase'>{project.health.label}</span> stage!</span>
            )
            UI.toastMessage(messageContent);
        }
        changeUser({...user});
    };

    return (
        <div className="BlankHealthCard" onClick={handleClick}>
            <div className='button'>
                <img src="choose-stage.svg" className='icon-img' />
                <div className='label'>Choose stage</div>
            </div>
        </div>
    )
}

export default BlankHealthCard;