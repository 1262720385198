import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import './CompleteDayDrawer.scss';

function CompleteDayDrawer({user, changeUser, givePoints}) {

    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    function handleCloseClick() {
        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.completeDayDrawer = null;
            changeUser({...user});
            setLockStyle(false);
        }, 1000);

        console.log('trigger parentStyles trigger')
    };

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              handleCloseClick();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, user, changeUser]);

    let className = 'CompleteDayDrawer drawer';

    if (!user) {
        return (<div></div>);
    }
    if (!user.completeDayDrawer) {
        return (<div></div>);
    }

    if (!lockStyle && user.completeDayDrawer && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const parentStyles = {};
    parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    parentStyles.right = opacity === "0.5" ? "-480px" : "0px" 

    const handleCompleteDayClick = async () => {
        givePoints({
            'Impact': decideImpactReward(user),
            'Joy': decideJoyReward(user)
        });

        UI.toastMessageWithEarnings("Awesome job! You completed your day!", null, null, decideImpactReward(), null, decideJoyReward());

        user.completeDayDrawer = false;

        changeUser({...user});
    };

    console.log('completeday render');

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>
            <div className='big-subheading'>Great work!</div>
            <div className='big-heading'>
                You Finished<br />Your Day
            </div>

            <img src="/peak.png" className='peak-img' />

            <div className='rewards-subheading'>
                You'll Earn
            </div>

            <div className='rewards'>
                <div className="reward-content">
                    <div className='reward reward-impact'>
                        <div className='label'>{decideImpactReward(user)}</div>
                    </div>
                    <div className="rank-label rank-label-impact">Impact</div>
                </div>
                <div className="reward-content">
                    <div className='reward reward-joy'>
                        <div className='label'>{decideJoyReward(user)}</div>
                    </div>
                    <div className="rank-label rank-label-joy">Joy</div>
                </div>
            </div>

            <div className='complete-button' onClick={handleCompleteDayClick}>Complete Day</div>

        </div>
    );
};

function decideImpactReward() {
    return 15;
}

function decideJoyReward() {
    return 15;
}

function roundNearest5(num) {
    return Math.round(num / 5) * 5;
  }

export default CompleteDayDrawer;