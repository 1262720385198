import React, { useContext, useState } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import HealthCard from '../HealthCard/HealthCard';
import HealthOption from '../HealthGrid/HealthOption';
import './NewProjectCard.scss';

function NewProjectCard() {

    const {user, changeUser} = useContext(UserContext);

    const [isEditing, setIsEditing] = useState(false);
    const [boldLockText, setBoldLockText] = useState(false);
    const [title, setTitle] = useState("");
    const [healthName, setHealthName] = useState(null);
    const [showCreateButton, setShowCreateButton] = useState(false);

    const ranksNeeded = user.ranks_needed_for_project_slot;
    const isLocked = ranksNeeded > 0;

    const handleTitleChange = (e) => {
        setTitle(e.target.value);

        setShowCreateButton(title.length > 1);
    };

    const onStageSelection = (option) => {
        setHealthName(option.name);

        setShowCreateButton(title.length > 1);
    };

    const handleAvailableCardClick = () => {
        setIsEditing(true);
    };

    const handleSubmitClick = async () => {
        const newUser = await Back.createProject(title, healthName);

        if (!newUser) {
            return;
        }

        user.projects = [...newUser.projects];
        user.has_pending_project_slot = newUser.has_pending_project_slot;
        user.ranks_needed_for_project_slot = newUser.ranks_needed_for_project_slot;

        setIsEditing(false);
        setTitle('');
        setHealthName(null);

        changeUser({...user});

        UI.toastMessage(<span>Nice! You created <img src={'health/' + healthName.toLowerCase() + '.png'} className="image-phase" /><span className="level-phase">{title}</span> as a new project.</span>)

        console.log('createProject', newUser);
    }

    const stageOptions = [
        {
            id: 8,
            name: "PumpedUp",
            label: "Pumped Up",
            subLabel: "100% execution"
        },
        {
            id: 7,
            name: "TakingTime",
            label: "Easing In",
            subLabel: "100% execution"
        },

            {
                id: 6,
                name: "FindingMyWay",
                label: "Finding My Way",
                subLabel: "100% execution"
            },
            

        
    ];

    const tier = {
        id: 3,
        name: "early",
        label: "Early Stages",
        color: "#b7eb7b"
    };

    const handleIsLockedClick = () => {
        setBoldLockText(true);

        setTimeout(() => {
            setBoldLockText(false);
        }, 2000);
    };

    if (isEditing) {
        return (
            <div className="NewProjectCard is-editing">

                <div className='heading'>
                    <div className='plus-button'>
                        <img src="/plus.png" className='plus-button-img' />
                    </div>
                    <div className='heading-label'>New Project</div>
                </div>

                <div className='card-form'>
                    {/* <div className='field-heading'>Project Title</div> */}
                    <input type="text" autoFocus placeholder='Project Title' className='field-text' value={title} onChange={handleTitleChange} />

                    {/* <div className='field-heading'>Project Stage</div>
                    <div className='field-choose-stage'>
                        {stageOptions.map(option => <HealthOption option={option} key={option.id} isSelected={healthName === option.name} onOptionSelected={onStageSelection} tier={tier}></HealthOption>)}
                    </div> */}

                    {showCreateButton && (
                        <div className='field-submit-button' onClick={handleSubmitClick}>
                            Create Project
                        </div>
                    )}

                </div>
            </div>
        )
    }

    if (isLocked) {
        return (
            <div className="NewProjectCard is-locked" onClick={handleIsLockedClick}>
                <div className='heading'>
                    <div className='plus-button'>
                        <img src="/plus.png" className='plus-button-img' />
                    </div>
                    <div className='heading-label'>New Project</div>
                </div>
    
                <div className='requirement' style={{'color': boldLockText ? '#d6710d' : '#ffab58'}}>
                    Rank up {ranksNeeded} more time{ranksNeeded > 1 ? 's' : ''} to unlock this Project Slot.
                </div>
            </div>
        )
    }

    return (
        <div className="NewProjectCard is-available" onClick={handleAvailableCardClick}>
            <div className='heading'>
                <div className='plus-button'>
                    <img src="/plus.png" className='plus-button-img' />
                </div>
                <div className='heading-label'>New Project</div>
            </div>

            <div className='requirement'>
                This Project Slot is open. Ready for a new project?
            </div>
        </div>
    )
}

function ranksRequiredForSlot() {
    
}

export default NewProjectCard;