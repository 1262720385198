import React, { useContext } from 'react';
import { useEffect } from 'react';
import { createContext, useState } from 'react';
import Mast from '../components/Mast/Mast';
import NewProjectCard from '../components/ProjectCard/NewProjectCard';
import ProjectCard from '../components/ProjectCard/ProjectCard';
import ProjectStub from '../components/ProjectStub/ProjectStub';
import ProjectGem from '../components/ProjectGem/ProjectGem';
import RankDashboard from '../components/RankDashboard/RankDashboard';
import Back from '../helpers/Back';
import UIContext from '../UIContext';
import UserContext from '../UserContext';
import './ProjectsScreen.scss';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import UI from '../helpers/UI';

function ProjectsScreen() {

    const {user, changeUser} = useContext(UserContext);

    if (!user) {
        return <div></div>;
    }

    const handleCoinClick = () => {
        user.donationDrawer = true;
        changeUser({...user});
      }
    

    return (
            <div className="ProjectsScreen">

                <Mast user={user} changeUser={changeUser}></Mast>

                <div className='main-layout'>
                    <div className='draggable-col'>
                        <div className="draggable-fixed">
                            <div className='projects-heading'>Your Journey</div>
                            
                            {/* <DraggableCoin></DraggableCoin> */}
                            {/* <RankDashboard></RankDashboard> */}
                        </div>
                    </div>
                    <div className='projects-col'>
                        <div className='projects-head'>
                            <div className='projects-heading'>Your Projects</div>
                            {/* <div className='DayProgress'>
                                <div className='node node-filled node-first'></div>
                                <div className='line line-left-nob line-left'></div>
                                <div className='line line-right'></div>

                                <div className='node node-mid'></div>
                                <div className='line line-left'></div>
                                <div className='line line-right-nob line-right'></div>

                                <div className='node node-filled node-mid'></div>
                                <div className='line line-left-nob line-left'></div>
                                <div className='line line-right'></div>

                                <div className='node node-mid'></div>
                                <div className='line line-left'></div>
                                <div className='line line-right'></div>

                                <div className='node node-end'></div>
                            </div> */}
                        </div>
                        <div className='cards'>
                            {user.projects.map(project => <ProjectCard project={project} key={project.id}></ProjectCard>)}
                            {user.has_pending_project_slot && <NewProjectCard ranksNeeded={user.ranks_needed_for_project_slot}></NewProjectCard>}
                        </div>
                    </div>

                    <div className="activity-col">
                        <div className='projects-heading day-heading'>Your Day</div>
                        {/* {user.projects.map(project => <ProjectStub project={project}></ProjectStub>)} */}

                        <div className="today-widget today-widget-only">
                            {/* <img src="/peak.png" className='peak-img' /> */}

                            {user.today_total_work_minutes >= user.today_total_work_goal && (
                                <div className='circle-done'>
                                    <img src="project-check4.svg" className='done-img' />
                                </div>
                            )}

                            {user.today_total_work_minutes < user.today_total_work_goal && (
                                                            <div className="circle">
                                                            <CircularProgressbar
                                                            value={user.today_total_work_minutes / user.today_total_work_goal * 100}
                                                            text={""}
                                                            styles={buildStyles({
                                                                pathColor: "#f86312",
                                                                trailColor: "#ffe2c0",
                                                                textSize: '12px',
                                                                textColor: "#fff"
                                                            })}
                                                            strokeWidth={16}
                                                        />
                                                    </div>
                            )}


                            <div className="minutes">{UI.formatMinutes(user.today_total_work_minutes)} <span className="minutes-total"> &nbsp;{UI.formatMinutes(user.today_total_work_goal)}</span></div>

                        </div>

                        <div className='projects-heading progress-heading'>Your Path</div>

                        <div className="ranks-widget">
                            <div className="widget-content">
                                <RankDashboard></RankDashboard>
                            </div>
                        </div>

                        <div className="ranks-widget coin-widget">
                            <div className="coin-content">
                                <div className="Ruby" onClick={handleCoinClick}>
                                    <div className='coin'>
                                    <img src="/coin-shadow2.png" className="shadow-img" />
                                    <img src="/star.svg" className="ruby-img" />  
                                    </div>
                                    <div className="ruby-count">{user ? user.ruby_count : ''}</div>
                                </div>
                            </div>
                        </div>

                        
            {/* <div className="project-grades">
                <div className="ProjectGrade">
                    <div className="heading">
                        Velocity
                    </div>
                    <div className="legend">
                        <div className='legend-icon legend-icon-check'>
                        <div className={`time-box`}></div>
                        </div>
                        <div className='legend-icon legend-icon-coin'>
                            <img src="/star.svg" className="legend-icon-img" />
                        </div>
                    </div>
                    <div className="progress">
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{width: 200 + 'px'}}></div>
                        </div>
                    </div>
                </div>

                <div className="ProjectGrade Confidence">
                    <div className="heading">
                        Confidence
                    </div>
                    <div className="legend">
                        <div className='legend-icon legend-icon-check'>
                            <div className={`time-box`}></div>
                        </div>
                        <div className='legend-icon legend-icon-coin'>
                            <img src="/star.svg" className="legend-icon-img" />
                        </div>
                    </div>
                    <div className="progress">
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{width: 120 + 'px'}}></div>
                        </div>
                    </div>
                </div>
            </div> */}

                        
                    </div>
                    <div className='clear'></div>
                </div>
            </div>
            
        
    )
}

export default ProjectsScreen;