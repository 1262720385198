import toast from "react-hot-toast"

export default {
    formatMinutes: (number) => {
      const {hours, minutes} = toHoursAndMinutes(number);

      if (hours) {
        return `${hours}h ${minutes}m`;
      }

      return `${minutes}m`;
    },
    toastRankUp: (rank, level) => {

        toast(
            (t) => {
              return (
                <div className='ToastContent'>
                  <div className='message'>You reached <span className='level-phase'>Rank {level}</span> in <span className={`${rank}-phase`}>{rank}</span>!</div>
                  <div className='earned'>
                    <div className='earned-label'>
                      You earned
                    </div>
                    <div className='earned-items'>
                      <div className='earned-item'>
                        <div className='earned-number'>1</div>
                        <img src="/star.svg" className='earned-coin-img' />
                      </div>
                    </div>
                  </div>
                </div>
              )
            },
            {
            style: {
              //width: widths[rank],
              marginTop: '-56px',
              marginLeft: '4px',
              marginBottom: '60px',
              border: '1px solid #ccc',
              boxShadow: '0px 2px 4px 1px #eee'
            }
          });
    },
    toastMessageWithEarnings: (message, earnedRuby, earnedCoin, earnedImpact, earnedGrowth, earnedJoy) => {
        toast(
            (t) => {
              return (
                <div className='ToastContent'>
                  <div className='message'>{message}</div>
                  <div className='earned'>
                    <div className='earned-label'>
                      You earned
                    </div>
                    <div className='earned-items'>
                        {earnedCoin && (
                            <div className='earned-item'>
                            <div className='earned-number'>{earnedCoin}</div>
                            <img src="/star.svg" className='earned-coin-img' />
                            </div>
                        )}

                        {earnedImpact && (
                            <div className='earned-item'>
                                <div className='earned-rank earned-rank-impact'>+{earnedImpact} Impact</div>
                            </div>
                        )}
                        {earnedGrowth && (
                            <div className='earned-item'>
                                <div className='earned-rank earned-rank-growth'>+{earnedGrowth} Growth</div>
                            </div>
                        )}
                        {earnedJoy && (
                            <div className='earned-item'>
                                <div className='earned-rank earned-rank-joy'>+{earnedJoy} Ease</div>
                            </div>
                        )}
                    </div>
                  </div>
                </div>
              )
            },
            {
            style: {
              //width: widths[rank],
              marginTop: '-56px',
              marginLeft: '4px',
              marginBottom: '60px',
              border: '1px solid #ccc',
              boxShadow: '0px 2px 4px 1px #eee'
            }
          });
    },
    toastMessage: (message) => {
        toast(
            (t) => {
              return (
                <div className='ToastContent'>
                  <div className='message'>{message}</div>
                </div>
              )
            },
            {
            style: {
              //width: widths[rank],
              marginTop: '-56px',
              marginLeft: '4px',
              marginBottom: '60px',
              border: '1px solid #ccc',
              boxShadow: '0px 2px 4px 1px #eee'
            }
          });
    },
    showWarningMessage: (message) => {
      toast(
        (t) => {
          return (
            <div className='ToastContent'>
              <div className='message'>{message}</div>
            </div>
          )
        },
        {
          position: 'bottom-center',
        style: {
          //width: widths[rank],
          border: '1px solid #ccc',
          boxShadow: '0px 2px 4px 1px #eee'
        }
      });
    },
    isProjectOnLeftOrRight: (user, project) => {
      return 'left';
      
        const projectIndex = user.projects.findIndex(p => p.id === project.id);
    
        if (projectIndex % 2 === 0) {
            return 'left';
        }
    
        return 'right';
    }
}

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}