import React, { useContext, useRef, useState } from 'react';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import HealthGrid from '../HealthGrid/HealthGrid';
import './HealthGridDrawer.scss';

function HealthGridDrawer({}) {

    const ref = useRef();
    const {user, changeUser} = useContext(UserContext);

    if (!user) {
        return (<div></div>);
    }
    if (!user.healthGridDrawer) {
        return (<div></div>);
    }

    let className = 'HealthGridDrawer';

    const project = user.projects[0];
    var leftOrRight = UI.isProjectOnLeftOrRight(user, project);
    const onSelection = user.healthGridDrawerOnSelection;

    const parentStyles = {};
    if (leftOrRight === 'left') {
        parentStyles.right = '0px';
    } else {
        parentStyles.left = '0px';
    }

    return (
        <div className={className} ref={ref} style={parentStyles}>

            <div className='title'>
                Choose Project Stage
            </div>

            <div className='project-title'>
                {project.title}
            </div>

            <HealthGrid project={project} onSelection={onSelection}></HealthGrid>
        </div>
    )
}

export default HealthGridDrawer;