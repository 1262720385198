import React, { useState } from 'react';
import './HealthOption.scss';

function HealthOption({option, tier, isSelected, onOptionSelected}) {

    const handleClick = () => {
        onOptionSelected(option);
    }

    let className = 'HealthOption';
    if (isSelected) {
        className += ' is-selected';
    }
    className += ' ' + tier.name;

    return (
        <div className={className} onClick={handleClick}>
            <div className="icon">
                <img src={'health/' + option.name.toLowerCase() + '.png'} alt="Health" className="icon-img" />
            </div>
            <div className="content">
                <div className='health-label' dangerouslySetInnerHTML={{ __html: option.label }}>
                    
                </div>
                <div className='health-since'>
                    {option.subLabel}
                </div>
            </div>
        </div>
    )
}

export default HealthOption;