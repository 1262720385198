import React, { useState } from 'react';
import './HealthGrid.scss';
import HealthOption from './HealthOption';

function HealthGrid({project, onSelection}) {

    const optionTiers = [
        {
            id: 1,
            name: "end",
            label: "End Stages",
            color: "#ffbb8e",
            options: [
                {
                    id: 51,
                    name: "Gliding",
                    label: "Gliding",
                    subLabel: "100% execution",
                    more: "You've reached a steady cadence of progress and effort with this project.",
                    tierName: "end"
                },
                {
                    id: 1,
                    name: "Roaring",
                    label: "Rocking Out",
                    subLabel: "100% execution",
                    more: "You have the energy and skills needed here, and you're getting a lot done.",
                    tierName: "end"
                },
                {
                    id: 50,
                    name: "Crunching",
                    label: "Crunch Mode",
                    subLabel: "100% execution",
                    more: "This project is nearing completion and you just need to push it to the finish line.",
                    tierName: "end"
                },
            ]
        },
        {
            id: 2,
            name: "mid",
            label: "Mid Stages",
            color: "#ffe88e",
            options: [
                {
                    id: 3,
                    name: "Tending",
                    label: "Tending",
                    subLabel: "100% execution",
                    more: "Whether because of a large workload, creative resistance, or other reasons, you'll just do the best you can for now.",
                    tierName: "mid"
                },
                {
                    id: 4,
                    name: "FiguringItOut",
                    label: "Figuring It Out",
                    subLabel: "100% execution",
                    more: "There's a stretch of poking and prodding needed right now, and progress may be stop and go.",
                    tierName: "mid"
                },
                {
                    id: 5,
                    name: "MovingAlong",
                    label: "Moving Along",
                    subLabel: "100% execution",
                    more: "Things are moving along smoothly and good progress can be made.",
                    tierName: "mid"
                },
                {
                    id: 80,
                    name: "FastTracking",
                    label: "Fast-tracking",
                    subLabel: "100% execution",
                    more: "This project is ready for execution and needs to be prioritized.",
                    tierName: "mid"
                }
            ]
        },
        {
            id: 3,
            name: "early",
            label: "Early Stages",
            color: "#b7eb7b",
            options: [
                {
                    id: 7,
                    name: "TakingTime",
                    label: "Easing&nbsp;In",
                    subLabel: "100% execution",
                    more: "You need to take your time getting into the flow of this project.",
                    tierName: "early"
                },
                {
                    id: 8,
                    name: "PumpedUp",
                    label: "Pumped Up",
                    subLabel: "100% execution",
                    more: "You're feeling energized about this project and are ready to start.",
                    tierName: "early"
                },
                {
                    id: 6,
                    name: "FindingMyWay",
                    label: "Finding My&nbsp;Way",
                    subLabel: "100% execution",
                    more: "You need some time to experiment and tinker with this project first.",
                    tierName: "early"
                },
            ]
        }
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    const onOptionSelected = (item) => {
        setSelectedOption(item);
        //onSelection(item);
    };

    return (
        <div className="HealthGrid">
            {optionTiers.map(
                (tier) => {
                    return (
                        <div className='tier'>
                            <div className={`tier-label`}>
                                <div className={`tier-label-badge tier-label-${tier.name}`}>{tier.label}</div>
                            </div>
                            <div className='tier-options'>
                                {tier.options.map(option => <HealthOption option={option} key={option.id} tier={tier} isSelected={selectedOption && selectedOption.id === option.id} onOptionSelected={onOptionSelected}></HealthOption>)}
                            </div>
                        </div>
                    );
                }
            )}
            {selectedOption && (
                <div className="controls">
                    <div className="selected-content">
                        <div className='selected-option'>
                            <div className="icon">
                                <img src={'health/' + selectedOption.name.toLowerCase() + '.png'} alt="Health" className="icon-img" />
                            </div>
                            <div className="content">
                                <div className='health-label' dangerouslySetInnerHTML={{ __html: selectedOption.label }}>
                                    
                                </div>
                            </div>
                            {
                                selectedOption.tierName === "end" && !project.has_unlocked_end_tier ? 
                                    <div className='disable-message'>Complete an Early or Mid Stage before starting an End Stage.</div> : 
                                    <div className='select-button' onClick={() => onSelection(selectedOption)}>Start Stage</div>
                            }
                            
                        </div>
                        <div className="selected-more">{selectedOption.more}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HealthGrid;