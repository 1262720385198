import React from 'react';
import { useState } from 'react';
import './ThoughtOption.scss';

function ThoughtOption({project, thought, onThoughtFilling, onThoughtSelected}) {

    const [totalOpacity, setTotalOpacity] = useState('1');
    const [filledWidth, setFilledWidth] = useState('0%');
    const [filledOpacity, setFilledOpacity] = useState('0');
    const [labelIconMargin, setLabelIconMargin] = useState("-18px");
    const [labelIconOpacity, setLabelIconOpacity] = useState("0");

    if (thought.is_fading) {
        //setTotalOpacity('0');
    }

    const handleThoughtClick = () => {

        setFilledWidth('86%');
        setFilledOpacity(1);

        setLabelIconMargin("0");
        setLabelIconOpacity("1");

        onThoughtFilling(thought);

        setTimeout(() => {
            // Animate out.
        }, 2000);

        setTimeout(() => {
            onThoughtSelected(thought);
        }, 2800);
    }

    // if (thought.is_gone) {
    //     return (
    //         <div></div>
    //     );
    // }

    return (
        <div className="ThoughtOption" onClick={handleThoughtClick} style={{height: thought.is_gone ? '0px' : '44px', opacity: thought.is_fading ? '0' : totalOpacity}}>
            <div className="bars-container">
                <div className='empty-bar'></div>
                <div className='filled-bar' style={{width: filledWidth, opacity: filledOpacity, 'borderColor': getThoughtPrimaryColor(thought), 'backgroundColor': getThoughtLightColor(thought)}}></div>
                <div className='label'>
                    <img src={'health/' + project.health.name.toLowerCase() + '.png'} className="label-icon-img" style={{opacity: labelIconOpacity, marginLeft: labelIconMargin}} />
                    {thought.label}.
                </div>
            </div>
        </div>
    )
}

function getThoughtPrimaryColor(thought) {
    const colors = {
        'Impact': '#ffe5c7',
        'Growth': '#c0f3cf',
        'Joy': '#f4d7fa'
    };

    console.log('getColor', colors[thought.type], thought);

    return colors[thought.type];
};
function getThoughtLightColor(thought) {
    const colors = {
        'Impact': '#fffaf4',
        'Growth': '#f4fdf6',
        'Joy': '#fdf6fe'
    };

    return colors[thought.type];
};

export default ThoughtOption;