import React, { useContext } from 'react';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import Back from '../../helpers/Back';
import UI from '../../helpers/UI';
import UserContext from '../../UserContext';
import './ProjectMenu.scss';

function ProjectMenu({project}) {

    const {user, changeUser} = useContext(UserContext);
    const [showMenu, setShowMenu] = useState(false);
    const [showConfirmTrash, setShowConfirmTrash] = useState(false);
    const ref = useRef();  

    const handleMenuClick = () => {
        setShowMenu(prev => !prev);
    };

    const handleCompleteClick = () => {

        if (project.wins.length < 1) {
            UI.showWarningMessage(
                "Do at least one session for this project before completing it."
            );
            return;
        }

        user.completeProjectDrawer = project;
        changeUser({...user});

        setTimeout(() => {
            setShowMenu(false);
        }, 50);
        
    };

    const handleTrashClick = (e) => {
        setShowConfirmTrash(prev => !prev);
        e.stopPropagation();
    };

    const handleConfirmTrashClick = async (e) => {
        setShowConfirmTrash(prev => !prev);
        e.stopPropagation();

        setTimeout(() => {
            setShowMenu(false);
        }, 50);

        const newUser = await Back.trashProject(project.id);

        if (!newUser) {
            return;
        }

        user.projects = newUser.projects;
        user.has_pending_project_slot = newUser.has_pending_project_slot;
        user.ranks_needed_for_project_slot = newUser.ranks_needed_for_project_slot;

        console.log('newUser', newUser);

        changeUser({...user});

        UI.toastMessage(
            <span>Your project <b>{project.title}</b> has been trashed.</span>
        );
    };

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowMenu(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);

    let className = "ProjectMenu";
    if (showMenu) {
        className += ' is-open';
    }

    return (
        <div  ref={ref}  className={className} onClick={handleMenuClick}>
            <img src="/three-dots.png" className="menu-img" />

            {showMenu && (
                <div className="dropmenu-container">
                    <div className="dropmenu">
                        <div className="item" onClick={handleCompleteClick}>
                            <img src="/complete-project.png" className='icon-img complete-icon' />
                            <div className="label">Complete</div>
                        </div>
                        <div className="item" onClick={handleTrashClick}>
                            <img src="/trash.svg" className='icon-img trash-icon' />
                            <div className="label">Trash</div>
                        </div>
                        {showConfirmTrash && (
                            <div className="item item-confirm-trash" onClick={handleConfirmTrashClick}>
                                <div className="label label-confirm-trash">Confirm Trash</div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </div>
    )
}

export default ProjectMenu;


